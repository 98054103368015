<template>
  <div
    class="industryTalent"
  >
    <div class="radioBox">
      <el-radio-group
        v-model="tabRadio"
        size="small"
        @change="handleRadioChange"
      >
        <el-radio-button label="岗位分析"></el-radio-button>
        <el-radio-button label="技能分析"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="newsEchartsBox" v-if="tabRadio == '岗位分析'">
      <div class="tech">
        <div class="tech-top">
          <div style="position: relative;width: 100%">
            <div class="selectType" style="top: 22vh; margin-left: 50px">
              <div
                class="img"
                style="width: 60px; height: 45px"
                @click="changeGlobalOrChina(1, 3)"
                v-if="mapTabTech == 2"
              >
                <div :class="mapTabTech == 1 ? 'high' : ''"></div>
                <img
                  style="width: 60px; height: 45px"
                  src="@/assets/images/china1.png"
                  alt
                />
              </div>
              <div
                class="img"
                @click="changeGlobalOrChina(2, 3)"
                style="width: 60px; height: 45px"
                v-else
              >
                <div :class="mapTabTech == 2 ? 'high' : ''"></div>
                <img
                  style="width: 60px; height: 45px"
                  src="@/assets/images/world1.png"
                  alt
                />
              </div>
            </div>
            <div style="display: flex">
              <world-new
                v-if="mapTabTech == 2"
                :data="worldListTech"
                eheight="30vh"
                style="width: 46%; margin-top: 0vw"
              ></world-new>
              <MapTalent
                v-if="mapTabTech == 1"
                style="width: 46%; margin-top: 0vw"
                eheight="30vh"
                :data="talentData"
              ></MapTalent>
              <CompanyPostBar
                v-loading="postCompanyLoading"
                :apiDataBarChart="postCompanyCount"
                style="width: 50%; margin-bottom: 20px"
              ></CompanyPostBar>
            </div>
          </div>
        </div>
        <div class="tech-bottom">
          <div style="display: flex">
            <IndustryTalentEcharts
              style="margin-left: 10px"
              v-loading="talentCountLoading"
              :apiDataLineChart="talentEchartsData"
            ></IndustryTalentEcharts>
            <PostCountBar
              v-loading="postCountLoading"
              :apiDataBarChart="postCount"
              style="width: 50%; margin-left: 20px"
            ></PostCountBar>
            <!-- <CompanyPostBar
              :apiDataBarChart="postCompanyCount"
              style="width: 50%; margin-top: 5%"
            ></CompanyPostBar> -->
          </div>
          <!-- <div style="margin-left: 20px;margin-top: 10px;height: 200px;width: 97%;border: 1px solid silver;border-radius: 4px;" v-if="tabRadio == '岗位分析'">岗位类型、职责</div> -->
          <!-- <div style="margin-left: 20px;margin-top: 10px;height: 200px;width: 97%;border: 1px solid silver;border-radius: 4px;" v-if="tabRadio == '技能分析'">核心技能分析</div> -->
          <el-radio-group
            v-model="post_type"
            size="small"
            style="margin-left: 15px; margin-top: 20px"
            @change="handleTypeChangePost"
          >
            <el-radio-button label="按公司"></el-radio-button>
            <el-radio-button label="按产品"></el-radio-button>
          </el-radio-group>
          <div style="margin-top: 10px;margin-left:15px;" v-if="filter_company || filter_post">
            当前筛选条件：<el-tag size="small" type="primary" closable @close="handleClose()" v-if="filter_company">{{ filter_company }}</el-tag>
            <el-tag size="small" type="warning" closable @close="handleClosePost()" v-if="filter_post" style="margin-left: 5px">{{ filter_post }}</el-tag>
          </div>
          <PostTable v-loading="postLoading" :apiData="post_list" :env="env" :post_type="post_type" :page="post_params.page" :page_size="post_params.page_size" :post_total="post_total" @changePostPage="handlePostPageChange"></PostTable>
          <!-- <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              margin: 10px 0 15px 0;
            "
          >
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="post_params.page"
              :total="post_total"
              :page-size="post_params.page_size"
              :page-count="5"
              @current-change="handlePostPageChange"
            >
            </el-pagination>
          </div> -->
          <!-- <div class="more">
            <p class="more-text" @click="showMore">加载更多</p>
          </div> -->
        </div>
      </div>
    </div>
    <div class="newsEchartsBox" v-if="tabRadio == '技能分析'">
      <div class="skillRequirement">
        <div>
          <el-radio-group
            v-model="skillTypeSelect"
            @change="handleFilter"
            size="small"
            style="margin-left: 18px; margin-bottom: 15px"
          >
            <el-tooltip
              v-for="i in skillRadioList"
              :key="i.name"
              class="item"
              effect="dark"
              :content="`包含 ${i.count} 个岗位`"
              placement="top"
            >
              <el-radio-button :label="i.name"></el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
        <div class="skillRequirement-top">
          <div
            style="
              height: 250px;
              overflow-y: scroll;
              width: 43%;
              display: flex;
              margin-right: 15px;
            "
          >
            <div
              class="skillCount"
              style="width: 75%; margin-left: 0%"
              v-loading="topLoading"
              element-loading-background="transparent"
            >
              <p
                style="
                  font-weight: bold;
                  color: white;
                  font-size: 18px;
                  margin-bottom: 10px;
                "
              >
                Top技能
              </p>
              <div
                class="progressBox"
                v-for="p in progressList"
                :key="p.name"
                style="margin-left: 15px; height: 47px"
              >
                <el-progress
                  :stroke-width="strokeWidth"
                  :percentage="(p.count / progressTotal) * 100"
                  :format="format"
                  :show-text="true"
                  :color="progressColor((p.count / progressTotal) * 100)"
                ></el-progress>
              </div>
              <!-- <div style="display: flex;flex-wrap: wrap">
                <div class="skillBox" v-for="(i, index) in progressList" :key="i.name" type="danger">{{index+1}}. {{i.name}}</div>
              </div> -->
            </div>
            <div style="margin-left: 15px; margin-right: 0px; margin-top: 24px">
              <p
                style="font-size: 18px; margin-bottom: 10px; color: transparent"
              ></p>
              <p style="height: 47px" v-for="p in progressList" :key="p.name">
                {{ p.name }}
              </p>
            </div>
          </div>
          <post-echarts
            v-loading="rankLoading"
            element-loading-background="transparent"
            :apiDataLineChart="newsEchartsData"
            style="width: 37% !important"
          ></post-echarts>
        </div>
        <!-- <div>
      <el-radio-group v-model="skillTypeSelect" @change="handleFilter" size="small" style="margin-left: 18px;margin-top: 15px;">
        <el-radio-button :label="i" v-for="i in skillTypeList" :key="i"></el-radio-button>
      </el-radio-group>
    </div> -->
        <div class="skillRequirement-bottom">
          <el-table
            :data="skillTableData"
            border
            style="width: 97.5%; margin-bottom: 1vw"
            @row-click="handleRowClick"
          >
            <el-table-column prop="skill" label="技能大类" width="180">
            </el-table-column>
            <el-table-column prop="post_category" label="技能小类" width="230">
            </el-table-column>
            <el-table-column prop="skill_word" label="技能" width="230">
            </el-table-column>
            <el-table-column prop="desc" label="技能需求" width="230">
            </el-table-column>
            <el-table-column prop="post" label="岗位"> </el-table-column>
            <el-table-column prop="num" label="招聘数"> </el-table-column>
            <el-table-column prop="address" label="地点"> </el-table-column>
            <el-table-column prop="salary" label="薪资"> </el-table-column>
            <el-table-column prop="education" label="学历"> </el-table-column>
            <el-table-column prop="company" label="公司"> </el-table-column>
            <el-table-column prop="date" label="时间"> </el-table-column>
          </el-table>
          <div
            style="display: flex; justify-content: center; margin-bottom: 10vh"
          >
            <el-pagination
              background
              v-if="skillTableData.length > 0"
              layout="prev, pager, next"
              :current-page.sync="techListParams.page"
              :total="techListTotal"
              :page-size="techListParams.page_size"
              :page-count="6"
              @current-change="handleTechPageChange"
            >
            </el-pagination>
          </div>
        </div>
        <div
          class="toolBox"
          v-show="showTooltips"
          @click="showTooltips = false"
        >
          <div class="tooltips">
            <div class="areaTitle">
              <p>
                <span style="color: #06b8f3">岗位详情</span>
              </p>
              <p class="areaTitle-close" @click="showTooltips = false">╳</p>
            </div>
            <div class="bombBox-content" style="padding: 0 2% 2% 2%">
              <div class="bombBox-center">
                <p class="post-name">{{ postDetail.name }}</p>
                <p class="post-company">{{ postDetail.company_name }}</p>
                <p class="post-info">
                  <span>{{ postDetail.region }}</span>
                  <span class="post-line">|</span>
                  <span>{{ postDetail.experience }}</span>
                  <span class="post-line">|</span>
                  <span>{{ postDetail.education }}</span>
                  <span class="post-line">|</span>
                  <span>招 {{ postDetail.num }} 人</span>
                  <span class="post-line">|</span>
                  <span>{{ postDetail.release_date }}发布</span>
                  <span class="post-line">|</span>
                  <span>{{ postDetail.source }}</span>
                </p>
                <div
                  class="post-tag"
                  type="primary"
                  size="mini"
                  v-for="i in postDetail.tags"
                  :key="i"
                >
                  {{ i }}
                </div>
                <p class="post-company">
                  职能类别：{{ postDetail.post_category }}
                </p>
                <!-- <p class="post-major">专业要求：</p>
                <p class="post-majorContent">{{ postDetail.major }}</p> -->
                <div
                  class="post-company"
                  v-html="postDetail.position_info"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog title="公司信息" :visible.sync="showInfo" width="70%">
        <div id="introduce" class="introduce">
          <!-- <img class="close" src="@/assets/img/close.png" @click="showIntroduce = false" /> -->
          <div class="detail">
            <div class="detail-mask" v-if="false">
              <p>当前未收录该公司的详细信息</p>
            </div>
            <div class="detailTop">
              <!-- <img src="@/assets/img/companyLogo.png" alt /> -->
              <div class="compLogo">
                <p>{{ compNameForLogo }}</p>
              </div>
              <div class="des">
                <h1>
                  {{ companyInfo.entity_name }}
                  <!-- <span>股票：{{ companyInfo.stock_code || "暂无" }}</span> -->
                </h1>
                <p>
                  <span>电话：{{ companyInfo.telephone || "暂无" }}</span>
                  <span
                    style="cursor: pointer"
                    @click="goto(companyInfo.website)"
                    >官网： {{ companyInfo.website || "暂无" }}</span
                  >
                </p>
                <p>
                  <span>邮箱：{{ companyInfo.email || "暂无" }}</span>
                  <span>地址：{{ companyInfo.office_addr || "暂无" }}</span>
                </p>
              </div>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>法定代表人</td>
                  <td>{{ companyInfo.legal_representative || "暂无" }}</td>
                  <td>公司类别</td>
                  <td>{{ companyInfo.company_type || "暂无" }}</td>
                  <td>成立日期</td>
                  <td>{{ companyInfo.founded_date || "暂无" }}</td>
                </tr>
                <tr>
                  <td>注册资本</td>
                  <td>{{ companyInfo.regis_capital || "暂无" }}</td>
                  <td>纳税人识别号</td>
                  <td>{{ companyInfo.regis_code || "暂无" }}</td>
                  <td>所属地区</td>
                  <td>{{ companyInfo.headquater || "暂无" }}</td>
                </tr>
                <tr>
                  <td>员工人数</td>
                  <td>{{ companyInfo.employees || "暂无" }}</td>
                  <td>公司英文</td>
                  <td>{{ companyInfo.name_en || "暂无" }}</td>
                  <td>是否上市</td>
                  <td>
                    {{ companyInfo.listed ? companyInfo.listed : "未知" }}
                  </td>
                </tr>
                <!-- <tr>
            <td>Moody’s</td>
            <td>{{data['commercial_info/Moody’s']||'暂无'}}</td>
            <td>income_structure</td>
            <td>{{data['commercial_info/income_structure']||'暂无'}}</td>
            <td>{{"S&P"}}</td>
            <td>{{data['commercial_info/S&P']||'暂无'}}</td>
            </tr>-->
                <tr>
                  <td>产业标签</td>
                  <td
                    colspan="5"
                    style="text-align: left; word-break: break-all"
                  >
                    {{ companyInfo.industry || "暂无" }}
                  </td>
                </tr>
                <tr>
                  <td>经营范围</td>
                  <td
                    colspan="5"
                    style="text-align: left; word-break: break-all"
                  >
                    {{ companyInfo.domain || "暂无" }}
                  </td>
                </tr>
                <tr>
                  <td>描述</td>
                  <td
                    colspan="5"
                    style="text-align: left; word-break: break-all"
                  >
                    {{ companyInfo.company_profile || "暂无" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <el-collapse
            class="collapseBox"
            v-model="collapseActiveNames"
            @change="handleCollapse"
          >
            <el-collapse-item title="▪ 产业标签" name="1">
              <div class="tagBox">
                <!-- <div class="tagBlue" v-for="i in tagList" :key="i">{{ i }}</div> -->
                <el-tag
                  class="tagBox-tag"
                  size="small"
                  v-for="i in tagList"
                  :key="i"
                  >{{ i }}</el-tag
                >
              </div>
              <div class="tagBox">
                <!-- <div class="sourceItem-tag" v-for="i in productList" :key="i">{{ i }}</div> -->
                <el-tag
                  class="tagBox-tag"
                  size="small"
                  v-for="i in productList"
                  :key="i"
                  type="success"
                  >{{ i }}</el-tag
                >
              </div>
              <div class="tagBox">
                <!-- <div class="sourceItem-tag2" v-for="i in industryPaquList" :key="i">{{ i }}</div> -->
                <el-tag
                  class="tagBox-tag"
                  size="small"
                  v-for="i in industryPaquList"
                  :key="i"
                  type="warning"
                  >{{ i }}</el-tag
                >
              </div>
            </el-collapse-item>
            <el-collapse-item title="▪ 产业链信息" name="2">
              <link-echarts
                :apiDataGraphChart="chartDataLink"
                :shortName="chartLinkShortName"
                :onlySub="onlySub"
              ></link-echarts>
            </el-collapse-item>
            <el-collapse-item title="▪ 财务信息" name="3">
              <div
                class="bombBox-content_v2"
                v-show="lineChartFinancialVisible"
              >
                <financial-echarts
                  ref="financialEcharts"
                  :apiDataLineChart="chartDataFinancial"
                ></financial-echarts>
              </div>
            </el-collapse-item>
            <el-collapse-item title="▪ 营收构成" name="4">
              <div class="bombBox-content_v2" v-show="lineChartProductVisible">
                <product-echarts
                  ref="productEcharts"
                  :apiDataLineChart="chartDataProduct"
                  :apiYearList="chartProductYearList"
                ></product-echarts>
              </div>
            </el-collapse-item>
            <el-collapse-item title="▪ 股东信息" name="5">
              <div class="bombBox-content_v2" style="margin-bottom: 10px">
                <table
                  class="holderTable"
                  border="1"
                  cellpadding="0"
                  cellspacing="0"
                  v-if="detailTrTopVal.length != 1"
                >
                  <tbody>
                    <tr v-for="t in detailTrTopVal" :key="t[0]">
                      <td v-for="(d, index) in t" :key="d + index.toString()">
                        {{ d }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- <div slot="footer" class="dialog-footer">
      <el-button @click="outerVisible = false" type="primary">关 闭</el-button>
    </div> -->
      </el-dialog>
      <el-dialog title="产业成本" :visible.sync="showCost">
        <el-table :data="tableCost">
          <el-table-column label="新能源车热泵系统">
            <el-table-column prop="part" label="零部件"></el-table-column>
            <el-table-column
              prop="price"
              label="单车价格(元)"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="2020">
            <el-table-column prop="in20" label="国内"></el-table-column>
            <el-table-column prop="out20" label="国际"></el-table-column>
          </el-table-column>
          <el-table-column label="2025">
            <el-table-column prop="in25" label="国内"></el-table-column>
            <el-table-column prop="out25" label="国际"></el-table-column>
          </el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog
        title="评分明细"
        width="98%"
        top="9vh"
        :visible.sync="showScoreDetail"
        :destroy-on-close="false"
      >
        <el-table
          :data="downstreamCompanyList"
          stripe
          style="
            width: 100%;
            margin-top: 10px;
            margin-bottom: 20px;
            overflow-x: auto !important;
          "
          border
          height="500"
        >
          <el-table-column prop="full_name" label="公司名" width="220" fixed>
          </el-table-column>
          <el-table-column prop="short_name" label="简称" width="120">
          </el-table-column>
          <el-table-column prop="board" label="板块" width="100">
          </el-table-column>
          <el-table-column
            prop="registered_capital"
            label="注册资本"
            sortable
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="staff_num"
            label="员工数量"
            sortable
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="ratepayer_num"
            label="纳税人识别号"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="address" label="地址" width="200">
          </el-table-column>
          <el-table-column
            prop="last_income"
            label="上一年营收"
            sortable
            width="170"
          >
          </el-table-column>
          <el-table-column
            prop="last_income_ratio"
            label="上一年营收增长率"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="last_profit"
            label="上一年净利润"
            sortable
            width="170"
          >
          </el-table-column>
          <el-table-column
            prop="last_profit_ratio"
            label="上一年净利润增长率"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="last_coreproduct"
            label="上一年核心产品"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="last_coreproduct_income"
            label="上一年核心产品营收"
            sortable
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="patent_sum"
            label="专利数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="last_patent_num"
            label="上一年专利数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="down_customer_num"
            label="下游公司数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="subsidiary_num"
            label="子公司数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="positive_num"
            label="正面舆情数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="half_positive_num"
            label="半年内正面舆情数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="negative_num"
            label="负面舆情数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="half_negative_num"
            label="半年内负面舆情数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="legal_num"
            label="法律文书数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="last_legal_num"
            label="一年内法律文书数量"
            sortable
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="industry_category"
            label="行业种类"
            width="100"
          >
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <el-dialog title="招聘信息" :visible.sync="showPost">
        <p class="postDetail-post">
          {{ postInfoDetail.name }}
          <span>{{ postInfoDetail.salary }}</span>
        </p>
        <p class="postDetail-company">{{ postInfoDetail.company_name }}</p>
        <p class="postDetail-info">
          <span> {{ postInfoDetail.region }} </span>
          <span class="postDetail-line">|</span>
          <span> {{ postInfoDetail.experience }} </span>
          <span class="postDetail-line">|</span>
          <span> {{ postInfoDetail.education }} </span>
          <span class="postDetail-line">|</span>
          <span> 招{{ postInfoDetail.num ? postInfoDetail.num : "1" }}人 </span>
          <span class="postDetail-line">|</span>
          <span>{{ postInfoDetail.release_date }} 发布</span>
          <span class="postDetail-line">|</span>
          <span>{{ postInfoDetail.source }}</span>
        </p>
        <div v-if="postInfoDetail.key_word">
          <el-tag
            type="primary"
            size="small"
            style="margin-right: 8px; margin-top: 10px"
            v-for="t in postInfoDetail.keyword_tags"
            :key="t"
            >{{ t }}</el-tag
          >
        </div>
        <div v-if="postInfoDetail.other_label">
          <el-tag
            type="success"
            size="small"
            style="margin-right: 8px; margin-top: 10px"
            v-for="t in postInfoDetail.tags"
            :key="t"
            >{{ t }}</el-tag
          >
        </div>
        <p style="margin-top: 10px" v-if="postInfoDetail.post_category">
          职能类别：{{ postInfoDetail.post_category }}
        </p>
        <p
          class="postDetail-position_info"
          v-html="postInfoDetail.position_info"
        ></p>
        <!-- <p class="postDetail-url"><a :href="postInfoDetail.url" style="color: #5C7BD9; font-weight: bold;font-size: 16px" target="_blank">前往原网页</a></p> -->
      </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";
import WorldNew from "../components/WorldNew.vue";
import MapIndustry from "../components/MapIndustry.vue";
import MapPatent from "../components/MapPatent.vue";
import MapTalent from "../components/MapTalent.vue";
import PatentCard from "../components/PatentCard.vue";
import ev from "../json/industry_link/ev.json";
import yiliao from "../json/industry_link/yiliao.json";
import yiliaoqixie from "../json/industry_link/yiliaoqixie.json";
import LinkEcharts from "../components/LinkEcharts";
import IndustryPatentEcharts from "../components/IndustryPatentEcharts.vue";
import IndustryTalentEcharts from "../components/IndustryTalentEcharts.vue";
import NewsKeywordCloud from "../components/NewsKeywordCloud.vue";
import CompanyPatentBar from "@/components/CompanyPatentBar.vue";
import CompanyPostBar from "@/components/CompanyPostBar.vue";
import PostCountBar from "@/components/PostCountBar.vue";
import FinancialEcharts from "../components/FinancialEcharts";
import ProductEcharts from "../components/ProductEcharts";
import PostEcharts from "../components/PostEcharts.vue";
import Star from "@/components/Star.vue"
import PostTable from "@/components/PostTable.vue"

export default {
  components: {
    PostEcharts,
    WorldNew,
    MapIndustry,
    PatentCard,
    MapPatent,
    MapTalent,
    IndustryPatentEcharts,
    NewsKeywordCloud,
    CompanyPatentBar,
    CompanyPostBar,
    IndustryTalentEcharts,
    PostCountBar,
    LinkEcharts,
    FinancialEcharts,
    ProductEcharts,
    Star,
    PostTable
  },
  data() {
    return {
      env: "",
      post_type: "按公司",
      filter_company: "",
      filter_post: "",
      skillRadioList: [
        {
          name: "全部",
          count: "0",
        },
        {
          name: "软件开发",
          count: "0",
        },
        {
          name: "机械设计",
          count: "0",
        },
        {
          name: "电路设计",
          count: "0",
        },
        {
          name: "市场客户",
          count: "0",
        },
        {
          name: "财务管理",
          count: "0",
        },
        {
          name: "材料开发",
          count: "0",
        },
        {
          name: "仿真软件",
          count: "0",
        },
        {
          name: "数据挖掘",
          count: "0",
        },
        {
          name: "通信网络",
          count: "0",
        },
        {
          name: "生产运营",
          count: "0",
        },
      ],
      showTooltips: false,
      skillTableData: [],
      rankLoading: false,
      progressTotal: 0,
      strokeWidth: 10,
      progressList: [],
      topLoading: false,
      postCountLoading: false,
      talentData: [],
      postLoading: false,
      skillTypeSelect: "全部",
      collapseActiveNames: ["1", "2", "3", "4", "5", "6"],
      tagList: [],
      productList: [],
      industryPaquList: [],
      talentEchartsData: {},
      chartDataLink: {},
      chartDataProduct: {},
      chartProductYearList: [],
      chartLinkShortName: "",
      onlySub: false, // 是否只拥有子公司
      talentCountLoading: false,
      lineChartProductVisible: false,
      post_list: [],
      post_params: {
        industry: "",
        company_id: "",
        page: 1,
        account: "",
        page_size: 10,
        industry_node: "",
        post_classification: "",
        filter_type: "相关公司岗位"
      },
      post_total: 0,
      link_industry: "",
      patent_list: [],
      postCompanyLoading: false,
      barLoading: false,
      downstreamLoading: false,
      patentLoading: false,
      patentScatterLoading: false,
      patentCountLoading: false,
      keywordLoading: false,
      downstreamCompanyList: [],
      downstreamCompanyListChina: [],
      downstreamCompanyListGlobal: [],
      industryDesc: "",
      industrySelected: "",
      domain_id: "",
      nodeDesc: "",
      tabRadio: "岗位分析",
      tableLoading: false,
      treeLoading: false,
      compNameForLogo: "",
      companyInfo: {
        entity_name: "",
        telephone: "",
        website: "",
        email: "",
        office_addr: "",
        description: "",
        label: "",
        legal_representative: "",
        company_type: "",
        founded_date: "",
        regis_capital: "",
        regis_code: "",
        headquater: "",
        employees: "",
        name_en: "",
        listed: "",
        domain: "",
        stock_code: "",
        industry: "",
        company_profile: "",
      },
      detailTrTopVal: [],
      tableCost: [
        {
          part: "电动压缩机",
          price: "1200",
          in20: "3",
          out20: "2.7",
          in25: "2.8",
          out25: "3.6",
        },
        {
          part: "四通换向阀",
          price: "150",
          in20: "0.3",
          out20: "0.7",
          in25: "2.8",
          out25: "3.6",
        },
        {
          part: "换热器",
          price: "300",
          in20: "3",
          out20: "2.7",
          in25: "2.8",
          out25: "3.6",
        },
      ],
      postInfoDetail: {
        address: "",
        company_name: "",
        education: "",
        experience: "",
        job_type: "",
        label: "",
        name: "",
        num: "",
        other_label: "",
        position_info: "",
        region: "",
        release_date: "",
        salary: "",
        source: "",
        url: "",
        tags: [],
        keyword_tags: [],
      },
      showInfo: false,
      showCost: false,
      tabsValue: "1",
      isFirst: true,
      tableData: [],
      tableDataChina: [],
      tableDataGlobal: [],
      newsEchartsData: {},
      patentEchartsData: [],
      wordCloudData: [],
      permissions: "",
      nodes: [],
      mapTabMarket: 1,
      mapTabTech: 1,
      tab: 1,
      mapLatent: [],
      mapLatentMarket: [],
      mapLatentTech: [],
      worldList: [],
      worldListMarket: [],
      worldListTech: [],
      links: [],
      optionList: [],
      loading: false,
      showScoreDetail: false, // 是否展示评分项明细
      company_id: "",
      patentParams: {
        industry: "",
        domain_id: "",
        company_id: "",
        page: 1,
        page_size: 5,
        filter_type: "相关公司专利",
      },
      formData: {
        full_name: "xxx公司",
        is_listed: "1",
        board: "深圳主板",
        income: "",
      },
      lineChartFinancialVisible: false,
      chartDataFinancial: [],
      companyPatentCount: {
        legend: [],
        data: [],
        name: {},
      },
      postInfoNodeParams: {
        account: "",
        industry: "",
        domain_id: 1,
        page: 1,
        page_size: 10
      },
      postCompanyCount: {
        // 岗位分析排行
        legend: [],
        data: [],
        name: {},
      },
      postCount: {
        // 岗位招聘排行
        legend: [],
        data: [],
        name: {},
      },
      links_position: {
        upUpStream: [],
        upStream: [],
        mediumStream: [],
        downStream: [],
      },
      positionLoading: false,
      showPost: false,
      newsList: [],
      techListParams: {
        technology: "",
        domain_id: "",
        industry: "",
        page: 1,
        page_size: 10,
        filter_type: "相关公司岗位"
      },
      postDetail: {
        address: "",
        company_name: "",
        education: "",
        experience: "",
        job_type: "",
        label: "",
        name: "",
        post_category: "",
        num: "",
        other_label: "",
        position_info: "",
        region: "",
        release_date: "",
        salary: "",
        source: "",
        url: "",
        tags: [],
        keyword_tags: [],
        major: "",
      },
    };
  },
  computed: {
    isOrNot() {
      if (this.formData.is_listed == 1) {
        return "是";
      } else {
        return "否";
      }
    },
  },
  methods: {
    handleTypeChangePost(val) {
      console.log("handleTypeChangePost", val)
      this.postInfoNodeParams.page = 1
      this.post_params.page = 1
      this.post_list = []
      if (val == '按公司') {
        this.getPostInfo()
      } else if (val == '按产品') {
        this.getPostInfoNode()
      }
    },
    // 点击当前筛选公司的x
    handleClose() {
      this.filter_company = ""
      this.patent_list = [];
      this.post_params.company_id = ""
      this.post_params.page = 1
      this.getPostInfo();
      this.getPostCount()
      this.getPostMonthRank()
    },
    // 点击当前筛选岗位类别的x
    handleClosePost() {
      this.filter_post = ""
      this.patent_list = [];
      this.post_params.post_classification = ""
      this.post_params.page = 1
      this.getPostInfo();
    },
    format(percentage) {
      return Math.ceil(((percentage / 100) * this.progressTotal).toFixed(2));
    },
    // 点击分页
    handleTechPageChange(val) {
      this.techListParams.page = val;
      this.getTechList();
    },
    // 获取技能需求排行
    async getTechCount() {
      this.progressList = [];
      // let skill_map = {
      //   软件开发: "嵌入式软件开发",
      //   机械设计: "大客户销售",
      //   电路设计: "BMS软件开发",
      //   市场客户: "",
      //   财务管理: "",
      //   材料开发: "",
      //   仿真软件: "MES项目经验",
      //   数据挖掘: "数据挖掘算法",
      //   通信网络: "驱动软件开发",
      //   生产运营: "掌握机械CAD",
      // }
      this.skillTypeList = [];
      let params = {
        domain_id: this.post_params.industry_node,
        industry: ""
      }
      await api.getTechCount(params).then((res) => {
        console.log("技能需求排行", res);
        Object.keys(res.data).forEach(async (i, index) => {
          // if (index < 5) {
          // this.progressTotal += res.data[i]
          // this.skillTypeList.push(i)
          this.skillTypeList.push({
            name: i,
            count: 0,
          });
          this.progressListOrigin.push({
            name: i,
            count: res.data[i],
          });
        });
      });
    },
    // 表格点击
    handleRowClick(val) {
      console.log("表格点击", val);
      this.getPostDetail(val.id);
    },
    progressColor(count) {
      if (count >= 20) {
        return "#06b8f3";
      } else if (count >= 10 && count < 20) {
        return "#E6A23C";
      } else {
        return "#F56C6C";
      }
    },
    handleFilter(name) {
      this.techListParams.page = 1;
      this.techListParams.technology = name;
      this.getTopCount(name);
      this.getTechList();
      this.getPostTrend();
    },
    // 获取技能需求列表
    getTechList() {
      this.techListParams.domain_id = this.post_params.industry_node
      api.getTechList(this.techListParams).then((res) => {
        this.skillTableData = res.data;
        this.techListTotal = res.count;
      });
    },
    // 获取招聘统计(根据技能需求)
    getPostTrend() {
      let params = {
        technology: this.techListParams.technology,
        domain_id: this.post_params.industry_node,
        filter_type: "相关公司岗位",
        industry: ""
      };
      // this.rankLoading = true
      api.getPostTrend(params).then((res) => {
        console.log("招聘统计趋势", res);
        this.newsEchartsData = res.data;
        // this.rankLoading = false
      });
    },
    // 获取top10统计
    getTopCount(val) {
      let params = {
        category_id: "",
        post_category: val,
        domain_id: this.post_params.industry_node,
        industry: ""
      };
      this.progressTotal = 0;
      this.progressList = [];
      this.topLoading = true;
      api.getTopCount(params).then((res) => {
        console.log("TOP统计", res);
        Object.keys(res.data).forEach((i) => {
          this.progressList.push({
            name: i,
            count: res.data[i],
          });
          this.progressTotal += res.data[i];
        });
        this.topLoading = false;
      });
    },
    // 监听树节点点击
    listenTreeClick() {
      EventBus.$on("tree_click", (data) => {
        this.post_params.industry_node = data.node_id;
        this.postInfoNodeParams.domain_id = data.node_id
        if (this.post_type == "按公司") {
          this.getPostInfo();
        } else if (this.post_type == "按产品") {
          this.getPostInfoNode()
        }
        this.getPostMonthRank();
        this.getPostRank();
        this.getPostScatter(data.node_id);
        this.getPostCount();
        this.getTechCount()
        this.getPostTrend()
        this.getTopCount("全部")
        this.getTechList()
      });
    },
    // 获取股东信息
    getHolder(company_id) {
      let params = {
        company_id: company_id,
        page: 1,
        page_size: 5,
      };
      // this.pdfLoading = true;
      api
        .getPdfAnnual(params)
        .then((res) => {
          console.log("年度报告", res);
          this.newsList = res.data;
          console.log("最新的newsList", this.newsList[0]);
          this.detailTrTopVal = [];
          // 载入最新股东信息
          this.detailTrTopVal[0] = [
            "股东名称",
            "股东性质",
            "持股比例",
            "持股数量",
            "持有有限条件的股份数量",
          ];
          if (this.newsList.length != 0 && this.newsList[0].shareholder) {
            this.newsList[0].shareholder.forEach((i) => {
              this.detailTrTopVal.push([
                i.name,
                i.attribute,
                i.share_ratio,
                i.share_num,
                i.share_condition_num,
              ]);
            });
          }
          // this.pageTotal = this.newsList.length;
          // this.pageTotal = res.total;
          // this.pdfLoading = false;
        })
        .catch((err) => {
          this.pdfLoading = false;
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取财务信息和产品信息
    async getFinancialAndProductInfo(company_id) {
      // this.companyInfoVisible = true;
      let params = {
        company_id: company_id,
      };
      // this.loading = true;
      await api
        .getFinancialInfo(params)
        .then((res) => {
          console.log("financial", res);
          this.chartDataFinancial = res.data;
          if (res.data.length != 0) {
            this.lineChartFinancialVisible = true;
          } else {
            this.lineChartFinancialVisible = false;
          }
        })
        .catch((err) => {
          // this.loading = false;
          console.log(err);
        });
      await api.getProductInfo(params).then((res) => {
        console.log("产品信息", res);
        this.chartDataProduct = res.data;
        this.chartProductYearList = res.year_list;
        if (res.year_list.length != 0) {
          this.lineChartProductVisible = true;
        } else {
          this.lineChartProductVisible = false;
        }
      });
      this.$nextTick(() => {
        // this.loading = false;
      });
    },
    // 获取产业链上下游
    async getLink(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.linkLoading = true;
      await api
        .getLink(params)
        .then((res) => {
          console.log("LINK", res);
          this.chartDataLink = res.data;
          if (
            res.data.chain_company_link.length == 0 &&
            res.data.chain_product_link.length == 0
          ) {
            this.onlySub = true;
          } else {
            this.onlySub = false;
          }
          // this.chartLinkShortName = this.short_name;
          // this.linkLoading = false;
        })
        .catch((err) => {
          // this.linkLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
      // this.setGraphChart();
    },
    // 获取公司产业标签
    getProductTag(company_id) {
      this.tagList = [];
      this.productList = [];
      this.industryPaquList = [];
      let params = {
        company_id: company_id,
      };
      // this.tagLoading = true;
      api
        .getProductTag(params)
        .then((res) => {
          console.log("产业标签", res);
          // this.productList = res.data.product;
          // 去除为空的标签
          res.data.product.forEach((i) => {
            if (i != "") {
              this.productList.push(i);
            }
          });

          // 正式环境
          // res.data.product_list.forEach(i => {
          //   this.industryPaquList.push(i)
          // })
          // console.log("indexof");
          // this.industryPaquList = [...new Set(this.industryPaquList)];
          // 正式环境

          if (res.data.product_detail) {
            this.tagList = res.data.product_detail.split("；");
            this.tagList.pop();
            this.tagList = [...new Set(this.tagList)];
          }
          if (res.data.industry_paqu) {
            this.industryPaquList = res.data.industry_paqu.split("；");
            this.industryPaquList.pop();
          }

          // this.tagLoading = false;
        })
        .catch((err) => {
          // this.tagLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
    },
    handleCollapse(val) {
      console.log("collapse", val);
      if (val.indexOf("3") != -1) {
        EventBus.$emit("financial_rerender", 1);
      }
      if (val.indexOf("4") != -1) {
        EventBus.$emit("product_rerender", 1);
      }
    },
    // 获取公司company_id
    getCompanyId(full_name) {
      let params = {
        full_name: full_name,
      };
      api.getCompanyId(params).then((res) => {
        console.log("获取公司company_id", res.data);
        return res.data;
      });
    },
    // 监听招聘岗位排行点击
    listenCompanyPostRank() {
      EventBus.$on("company_post_rank", (data) => {
        this.post_params.post_classification = data;
        this.filter_post = data
        this.post_params.page = 1;
        this.postInfoNodeParams.page = 1
        // this.post_params.company_id = "";
        this.getPostInfo();
      });
    },
    // 获取招聘岗位统计
    getPostCount() {
      // this.postCount.legend = [];
      // this.postCount.data = [];
      // this.postCount.name = {};
      let params = {
        industry: this.patentParams.industry,
        // industry_node: node,
        filter_type: "相关公司岗位",
        industry_node: this.post_params.industry_node,
        company_id: this.post_params.company_id
      };
      this.postCountLoading = true;
      api
        .getPostCount(params)
        .then((res) => {
          this.postCount.legend = [];
          this.postCount.data = [];
          this.postCount.name = {};
          console.log("岗位统计", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.postCount.legend.push(legend_item);
            this.postCount.name[legend_item] = i.post_all;
            this.postCount.data.push({
              value: i.count,
            });
          });
          this.postCountLoading = false;
        })
        .catch((err) => {
          this.postCountLoading = false;
        });
    },
    // 获取招聘散点分布
    getPostScatter(node) {
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
        filter_type: "相关公司岗位"
      };
      this.talentData = [];
      api.getPostScatter(params).then((res) => {
        // console.log("招聘散点", res)
        this.talentData = res.data;
      });
    },
    // 获取招聘月度排行
    getPostMonthRank() {
      let params = {
        industry: this.patentParams.industry,
        // industry_node: node,
        filter_type: "相关公司岗位",
        industry_node: this.post_params.industry_node,
        company_id: this.post_params.company_id
      };
      this.talentCountLoading = true
      api.getPostMonthRank(params).then((res) => {
        // console.log("月度排行", res)
        this.talentEchartsData = res.data;
        this.talentCountLoading = false
      });
    },
    // 获取公司招聘排行
    getPostRank() {
      let params = {
        industry: this.patentParams.industry,
        industry_node: this.post_params.industry_node,
        filter_type: "相关公司岗位"
      };
      this.postCompanyLoading = true;
      // this.postCompanyCount.legend = [];
      // this.postCompanyCount.data = [];
      // this.postCompanyCount.name = {};
      api
        .getPostRank(params)
        .then((res) => {
          this.postCompanyCount.legend = [];
          this.postCompanyCount.data = [];
          this.postCompanyCount.name = {};
          // console.log("招聘排行", res)
          res.data.forEach((i) => {
            let legend_item =
              i.short_name.length > 4
                ? i.short_name.substring(0, 4) + "..."
                : i.short_name;
            this.postCompanyCount.legend.push(legend_item);
            this.postCompanyCount.name[legend_item] = i.full_name;
            // this.postCompanyCount.data.push(i.num);
            this.postCompanyCount.data.push({
              value: i.count,
              company_id: i.company_id,
            });
          });
          this.postCompanyLoading = false;
        })
        .catch((err) => {
          this.postCompanyLoading = false;
        });
      // console.log("postCompanyCount", this.postCompanyCount)
    },
    // 分页器变化
    handlePostPageChange(val) {
      console.log("分页", val)
      if (this.post_type == "按公司") {
        this.post_params.page = val;
        this.getPostInfo();
      } else {
        this.postInfoNodeParams.page = val
        this.getPostInfoNode()
      }
    },
    // 获取公司招聘信息(按产品)
    getPostInfoNode() {
      this.postLoading = true;
      api.getPostInfoNode(this.postInfoNodeParams).then(res => {
        console.log("获取公司招聘信息(按产品)", res)
        this.post_list = res.data;
        this.post_total = res.count;
        this.postLoading = false;
      })
    },
    // 获取公司招聘信息
    getPostInfo() {
      // let params = {
      //   company_id: "",
      //   page: 1,
      //   page_size: 10
      // }
      this.postLoading = true;
      api
        .getPostInfo(this.post_params)
        .then((res) => {
          // console.log(res.data)
          this.post_list = res.data;
          this.post_total = res.count;
          this.postLoading = false;
        })
        .catch((err) => {
          this.postLoading = false;
        });
    },
    // 过滤类型切换
    handleTypeChange(val) {
      // console.log(val);
      if (this.patentParams.domain_id) {
        this.patent_list = [];
        this.getIndustryPatent();
      }
    },
    // 获取产业生态位
    getIndustryPosition() {
      let params = {
        industry:
          this.patentParams.industry != "air_conditioning"
            ? this.patentParams.industry
            : "ele_car",
      };
      this.positionLoading = true;
      api.getIndustryPosition(params).then((res) => {
        // console.log("生态位", res);
        this.links_position = {
          upUpStream: [],
          upStream: [],
          mediumStream: [],
          downStream: [],
        };
        res.data.forEach((i) => {
          if (i.chain == 0) {
            if (!i.father_id) {
              this.links_position.upUpStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
          if (i.chain == 1) {
            if (!i.father_id) {
              this.links_position.upStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
          if (i.chain == 2) {
            if (!i.father_id) {
              this.links_position.mediumStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
          if (i.chain == 3) {
            if (!i.father_id) {
              this.links_position.downStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
        });
        res.data.forEach((i) => {
          if (i.chain == 0 && i.father_id) {
            this.links_position.upUpStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 1 && i.father_id) {
            this.links_position.upStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 2 && i.father_id) {
            this.links_position.mediumStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 3 && i.father_id) {
            this.links_position.downStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
        });
        console.log("数据格式化", this.links_position);
        this.positionLoading = false;
      });
    },
    // 点击查看评分项明细
    async handleCheckDetail() {
      this.showScoreDetail = await true;
      // setTimeout(() => {
      //   document.getElementsByClassName(
      //     "el-table__body-wrapper is-scrolling-middle"
      //   )[0].scrollLeft = 0;
      // }, 500)
    },
    // 获取公司评分列表
    getCompanyScore() {
      let params = {
        node_id: this.domain_id,
      };
      this.downstreamLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.downstreamCompanyList = [];
      this.downstreamCompanyListChina = [];
      this.downstreamCompanyListGlobal = [];
      api.getCompanyScore(params).then((res) => {
        console.log("公司评分列表", res);
        res.data.forEach((i) => {
          if (i.short_name) {
            i.name = i.short_name;
          } else {
            i.name = i.full_name;
          }
          // this.downstreamCompanyList.push(i);
          if (i.country == "中国") {
            this.downstreamCompanyListChina.push(i);
          } else {
            this.downstreamCompanyListGlobal.push(i);
          }
        });
        if (this.mapTabMarket == 1) {
          this.downstreamCompanyList = this.downstreamCompanyListChina;
        } else {
          this.downstreamCompanyList = this.downstreamCompanyListGlobal;
        }
        let arrProvince = new Set();
        let arrCountry = new Set();
        res.data.forEach((i) => {
          if (i.loc_province && i.country == "中国") {
            arrProvince.add(i.loc_province.replace("省", "").replace("市", ""));
          }
          if (i.country && i.country != "中国") {
            arrCountry.add(i.country);
          }
        });
        arrProvince.forEach((i) => {
          latentCompAreaList.push({
            loc_province: i,
            value: 0,
          });
        });
        arrCountry.forEach((i) => {
          globalCompAreaList.push({
            name: i,
            value: 0,
          });
        });
        res.data.forEach((item) => {
          for (let i = 0; i < latentCompAreaList.length; i++) {
            if (
              latentCompAreaList[i].loc_province &&
              latentCompAreaList[i].loc_province ==
                item.loc_province.replace("省", "").replace("市", "")
            ) {
              latentCompAreaList[i].value += 1;
            }
          }
          for (let i = 0; i < globalCompAreaList.length; i++) {
            if (globalCompAreaList[i].name == item.country) {
              globalCompAreaList[i].value += 1;
            }
          }
        });
      });
      this.mapLatentMarket = latentCompAreaList;
      this.worldListMarket = globalCompAreaList;
      this.downstreamLoading = false;
    },
    // 获取产业对应公司的专利排行
    getCompanyPatentCount() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.companyPatentCount.legend = [];
      this.companyPatentCount.data = [];
      this.companyPatentCount.name = {};
      this.barLoading = true;
      let startTime = new Date().valueOf();
      api
        .getCompanyPatentCount(params)
        .then((res) => {
          console.log("公司专利排行", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.companyPatentCount.legend.push(legend_item);
            this.companyPatentCount.name[legend_item] = i.full_name;
            // this.companyPatentCount.data.push(i.num);
            this.companyPatentCount.data.push({
              value: i.num,
              company_guogao_id: i.company_guogao_id,
            });
          });
          this.barLoading = false;
          console.log("companyPatentCount", this.companyPatentCount);
          let endTime = new Date().valueOf();
          console.log(
            `%c专利排行：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.barLoading = false;
        });
    },
    // 公司列表查看明细
    checkDetail(row) {
      console.log("明细", row);
      this.showScoreDetail = true;
    },
    // 获取产业专利关键词
    getIndustryPatentKeyword() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
        company_guogao_id: this.patentParams.company_guogao_id,
      };
      this.keywordLoading = true;
      api
        .getIndustryPatentKeyword(params)
        .then((res) => {
          console.log("产业专利关键词", res);
          this.wordCloudData = res.data;
          this.keywordLoading = false;
        })
        .catch((err) => {
          this.keywordLoading = false;
        });
    },
    // 获取产业专利年度统计
    getIndustryPatentCount() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.patentCountLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatentCount(params)
        .then((res) => {
          console.log("年度统计", res);
          this.patentEchartsData = res.data;
          this.patentCountLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c产业专利年度统计：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.patentCountLoading = false;
        });
    },
    // 获取产业市场简介
    getIndustryIntroduction() {
      let params = {
        industry_id: this.domain_id,
      };
      api.getIndustryIntroduction(params).then((res) => {
        console.log("产业简介", res);
        this.industryDesc = res.data;
      });
    },
    // 加载更多
    showMore() {
      this.patentParams.page += 1;
      this.getIndustryPatent();
    },
    // 获取产业专利
    getIndustryPatent() {
      // this.patent_list = []
      this.patentLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatent(this.patentParams)
        .then((res) => {
          console.log("产业专利", res);
          res.data.forEach((r) => {
            this.patent_list.push(r);
          });
          this.patentLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c获取产业专利：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
          // this.patent_list = res.data
        })
        .catch((err) => {
          this.patentLoading = false;
        });
    },
    // 选择tab
    handleRadioChange(val) {
      console.log(val);
      if (val == "岗位分析") {
        // this.post_params.industry_node = "";
        this.post_params.industry = this.patentParams.industry;
        this.getIndustryTree(this.industrySelected);
        this.getPostInfo();
        this.getPostRank();
        this.getPostScatter("");
        this.getPostMonthRank();
        this.getPostCount();
      } else if (val == "技能分析") {
        this.getTechCount();
        this.getTopCount("全部");
        this.techListParams.technology = "全部";
        this.getTechList();
        this.skillRadioList.forEach(async (i) => {
          let params = {
            technology: i.name,
            domain_id: this.post_params.industry_node,
            industry: ""
          };
          let count = await api.getJobCount(params).then((res) => {
            console.log("大类统计", res.count);
            return res.count.toString();
          });
          console.log("count", count);
          i.count = count;
        });
        this.getPostTrend();
      }
    },
    // 找下标，删数组内容
    deleteArrayObject(arr, val) {
      let num = null;
      arr.forEach((a, index) => {
        if (a.code == val) {
          num = index;
        }
      });
      arr.splice(num, 1);
    },
    // 获取产业图谱选项
    getIndustryGraphOption() {
      this.optionList = [];
      let industry_graph_permission = cookie.getCookie("industry_graph");
      api.getIndustryGraphOption().then((res) => {
        console.log("option_res", res);
        // this.optionList = res.data;
        let res_data = JSON.parse(JSON.stringify(res.data));
        res.data.forEach((r, idx) => {
          // if (industry_graph_permission.indexOf(r.code) != -1) {
          //   this.optionList.push(r);
          // }
          r.option_list.forEach((o, index) => {
            if (industry_graph_permission.indexOf(o.code) == -1) {
              //   this.optionList.push(o);
              // } else {
              // console.log("else", o);
              // res_data[idx].option_list.splice(index, 1)
              this.deleteArrayObject(res_data[idx].option_list, o.code);
              // console.log("splice", res_data[idx]);
            }
          });
        });
        res_data.forEach((r) => {
          if (r.option_list.length > 0) {
            this.optionList.push(r);
          }
        });
        // this.optionList = res_data
        console.log("optionList", this.optionList);
        console.log("res_data", res_data);
      });
    },
    // 打开网页
    goto(web) {
      window.open(web);
    },
    // 获取公司详情信息
    async getCompanyInfo(row) {
      console.log("val", row);
      let params = {
        full_name: row.full_name,
      };
      await api.getCompanyInfo(params).then((res) => {
        console.log("公司信息", res);
        let info = res.data[0];
        this.companyInfo.entity_name = info.full_name;
        this.companyInfo.telephone = info.telephone;
        this.companyInfo.website = info.website;
        this.companyInfo.email = info.email;
        this.companyInfo.office_addr = info.address;
        this.companyInfo.description = "";
        this.companyInfo.label = "";
        this.companyInfo.legal_representative = info.representative;
        this.companyInfo.company_type = info.enterprises_type;
        this.companyInfo.founded_date = info.establishment;
        this.companyInfo.regis_capital = info.registered_capital;
        this.companyInfo.regis_code = info.taxpayer_num;
        this.companyInfo.headquater = info.region;
        this.companyInfo.employees = info.insured_num;
        this.companyInfo.name_en = info.english_name;
        this.companyInfo.listed = info.is_on_market;
        this.companyInfo.domain = info.business_scope;
        this.companyInfo.industry = info.industry;
        this.companyInfo.company_profile = info.company_profile;
        this.compNameForLogo = info.short_name.substring(0, 4);
        this.chartLinkShortName = info.short_name;
        this.showInfo = true;
      });
      let company_id = await api.getCompanyId(params).then((res) => {
        console.log("获取公司company_id", res.data);
        return res.data;
      });
      this.getProductTag(company_id);
      this.getLink(company_id);
      this.getFinancialAndProductInfo(company_id);
      this.getHolder(company_id);
    },
    // 获取下游公司列表
    async getDownstreamCompany(val) {
      let params = {
        category_id: val,
      };
      this.downstreamLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.downstreamCompanyList = [];
      this.downstreamCompanyListChina = [];
      this.downstreamCompanyListGlobal = [];
      await api
        .getIndustryCompany(params)
        .then((res) => {
          console.log("下游", res);
          this.downstreamCompanyList = [];
          this.downstreamCompanyListChina = [];
          this.downstreamCompanyListGlobal = [];
          // this.downstreamCompanyList = res.data
          res.data.forEach((r) => {
            if (r.country == "中国") {
              this.downstreamCompanyListChina.push(r);
            } else {
              this.downstreamCompanyListGlobal.push(r);
            }
          });
          if (this.mapTabMarket == 1) {
            this.downstreamCompanyList = this.downstreamCompanyListChina;
          } else {
            this.downstreamCompanyList = this.downstreamCompanyListGlobal;
          }
          let arrProvince = new Set();
          let arrCountry = new Set();
          res.data.forEach((i) => {
            if (i.loc_province && i.country == "中国") {
              arrProvince.add(
                i.loc_province.replace("省", "").replace("市", "")
              );
            }
            if (i.country && i.country != "中国") {
              arrCountry.add(i.country);
            }
          });
          arrProvince.forEach((i) => {
            latentCompAreaList.push({
              loc_province: i,
              value: 0,
            });
          });
          arrCountry.forEach((i) => {
            globalCompAreaList.push({
              name: i,
              value: 0,
            });
          });
          res.data.forEach((item) => {
            for (let i = 0; i < latentCompAreaList.length; i++) {
              if (
                latentCompAreaList[i].loc_province &&
                latentCompAreaList[i].loc_province ==
                  item.loc_province.replace("省", "").replace("市", "")
              ) {
                latentCompAreaList[i].value += 1;
              }
            }
            for (let i = 0; i < globalCompAreaList.length; i++) {
              if (globalCompAreaList[i].name == item.country) {
                globalCompAreaList[i].value += 1;
              }
            }
          });
          // this.downstreamLoading = false;
        })
        .catch((err) => {
          this.downstreamLoading = false;
          console.error(err);
        });
      // document.getElementsByClassName(
      //   "el-table__body-wrapper"
      // )[0].scrollTop = 0;
      this.mapLatentMarket = latentCompAreaList;
      this.worldListMarket = globalCompAreaList;
      this.downstreamLoading = false;
    },
    // 获取产业对应公司列表
    async getIndustryCompany(val) {
      let params = {
        category_id: val,
      };
      this.tableLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.tableData = [];
      this.tableDataChina = [];
      this.tableDataGloba = [];
      console.log("tab", this.tab);
      await api
        .getIndustryCompany(params)
        .then((res) => {
          this.tableData = [];
          this.tableDataChina = [];
          this.tableDataGloba = [];
          console.log("产业对应公司列表", res);
          // this.tableData = res.data;
          res.data.forEach((r) => {
            if (r.country == "中国") {
              // this.tableData.push(r)
              this.tableDataChina.push(r);
            } else {
              // this.tableData.push(r)
              this.tableDataGlobal.push(r);
            }
          });
          if (this.tab == 1) {
            this.tableData = this.tableDataChina;
          } else {
            this.tableData = this.tableDataGlobal;
          }
          let arrProvince = new Set();
          let arrCountry = new Set();
          res.data.forEach((i) => {
            if (i.loc_province && i.country == "中国") {
              arrProvince.add(
                i.loc_province.replace("省", "").replace("市", "")
              );
            }
            if (i.country && i.country != "中国") {
              arrCountry.add(i.country);
            }
          });
          arrProvince.forEach((i) => {
            latentCompAreaList.push({
              loc_province: i,
              value: 0,
            });
          });
          arrCountry.forEach((i) => {
            globalCompAreaList.push({
              name: i,
              value: 0,
            });
          });
          res.data.forEach((item) => {
            for (let i = 0; i < latentCompAreaList.length; i++) {
              if (
                latentCompAreaList[i].loc_province ==
                item.loc_province.replace("省", "").replace("市", "")
              ) {
                latentCompAreaList[i].value += 1;
              }
            }
            for (let i = 0; i < globalCompAreaList.length; i++) {
              if (globalCompAreaList[i].name == item.country) {
                globalCompAreaList[i].value += 1;
              }
            }
          });
        })
        .catch((err) => {
          this.tableLoading = false;
        });
      console.log("latentCompAreaList", latentCompAreaList);
      // 重置滚动条
      document.getElementsByClassName(
        "el-table__body-wrapper"
      )[0].scrollTop = 0;
      this.mapLatent = latentCompAreaList;
      this.worldList = globalCompAreaList;
      this.tableLoading = false;
    },
    // 获取产业树
    getIndustryTree(val) {
      let params = {
        root: val,
      };
      this.treeLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryTree(params)
        .then(async (res) => {
          console.log("产业树", res);
          res.data.nodes.forEach(async (n) => {
            n.category_id = n.id;
            n.id = undefined;
            n.level_independence = "high";
            // 计算第一个节点和1的差值，为了使节点的level整体偏移，因为该关系图默认显示level1和level2的节点
            let lv = (await res.data.nodes[0].level) - 1;
            // console.log("lv", lv)
            n.level -= lv;
            // console.log("level", n.level)
          });
          this.nodes = await res.data.nodes;
          this.links = await res.data.links;
          // console.log("nodes", this.nodes)
          // console.log("links", this.links)
          this.treeLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c产业树：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.treeLoading = false;
        });
    },
    // 切换全球/中国
    changeGlobalOrChina(val, flag) {
      // flag:1代表产业市场地图,2代表产业链条地图
      if (flag == 1 || flag == 2) {
        this.mapTabMarket = val;
        if (val == 1) {
          this.downstreamCompanyList = this.downstreamCompanyListChina;
        } else {
          this.downstreamCompanyList = this.downstreamCompanyListGlobal;
        }
      }
      if (flag == 2) {
        this.tab = val;
        //   if (val == 1) {
        //     this.tableData = this.tableDataChina;
        //   } else {
        //     this.tableData = this.tableDataGlobal;
        //   }
      }
      if (flag == 3) {
        this.mapTabTech = val;
        // if (val == 1) {
        //   this.tableData = this.tableDataChina;
        // } else {
        //   this.tableData = this.tableDataGlobal;
        // }
      }
      // this.getCompanyByProduct();
    },
    // 获取专利技术地图分布
    getIndustryPatentScatter() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.mapLatentTech = [];
      this.patentScatterLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatentScatter(params)
        .then((res) => {
          console.log("专利分布", res);
          this.mapLatentTech = res.data;
          this.patentScatterLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c专利分布：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.patentScatterLoading = false;
        });
    },
    // 获取产业结构各项数据
    getIndustryStructureData(i) {},
    // 点击产业
    handleClickIndustry(i) {
      console.log("点击产业", i);
      console.log(i.name);
      this.isFirst = false;
      // this.getCategoryLink(text);
      this.industrySelected = i.name;
      this.patentParams.industry = i.industry_en;
      console.log("industry_en", i.industry_en);
      this.domain_id = i.domain_id;
      this.patentParams.domain_id = i.domain_id;
      this.tableData = [];
      this.tableDataChina = [];
      this.tableDataGlobal = [];
      this.getIndustryTree(i.name);
      this.getIndustryPosition();
      this.getIndustryIntroduction();
      this.getCompanyScore();
      console.log("产业", i);
      // this.getDownstreamCompany(i.downstream_id);
      if (i.name == "新能源汽车" || i.name == "热泵空调") {
        this.link_industry = ev;
      } else if (i.name == "疫苗") {
        this.link_industry = yiliao;
      } else if (i.name == "呼吸机") {
        this.link_industry = yiliaoqixie;
      }
      // this.getIndustryCompany(i.domain_id);
      // this.getCompanyByCategory(i.text);
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        // this.newsType = ""
        // this.newsParams.company = ""
        this.company_id = "";
        this.tabsValue = "1";
        console.log("监听重置");
        this.isFirst = true;
        this.tabRadio = "产业市场";
        this.mapTabMarket = 1;
        this.tab = 1;
        this.patentParams.company_guogao_id = "";
        this.patent_list = [];
      });
    },
    // 监听图谱节点点击
    listenIndustryGraph() {
      EventBus.$on("industry_graph", (data) => {
        console.log("industry_graph", data);
        // this.getCompanyByCategory(data.industry_node);
        if (this.tabRadio == "产业链条") {
          this.nodeDesc = data.desc;
          // this.getIndustryCompany(data.domain_id);
          this.domain_id = data.domain_id;
          this.getCompanyScore();
        }
        if (this.tabRadio == "专利技术") {
          this.patent_list = [];
          this.patentParams.page = 1;
          this.patentParams.company_guogao_id = "";
          this.patentParams.domain_id = data.domain_id;
          this.getIndustryPatentCount();
          this.getIndustryPatent();
          this.getIndustryPatentScatter();
          this.getCompanyPatentCount();
          this.getIndustryPatentKeyword();
        }
        if (this.tabRadio == "岗位分析") {
          this.post_params.industry_node = data.domain_id;
          this.getPostScatter(data.domain_id);
          this.getPostRank(data.domain_id);
          this.getPostMonthRank(data.domain_id);
          this.getPostCount(data.domain_id);
          this.getPostInfo();
        }
      });
    },
    // 监听源产业变化
    listenSourceIndustry() {
      EventBus.$on("choose_source_industry", (data) => {
        console.log("监听源产业变化", data);
        this.patentParams.industry = data.industry_en;
        this.handleClickIndustry(data);
        this.tabRadio = "产业市场";
      });
    },
    // 监听岗位分析排行
    listenCompanyPostBar() {
      EventBus.$on("company_post_bar", (data) => {
        this.post_list = [];
        this.post_params.page = 1;
        this.postInfoNodeParams.page = 1
        this.post_params.company_id = data;
        // this.post_params.post_classification = "";
        this.getPostMonthRank()
        this.getPostCount()
        this.getPostInfo();
      });
    },
    // 监听排行bar的公司名变化
    listenCompanyPostBarName() {
      EventBus.$on("company_post_bar_name", data => {
        this.filter_company = data
      })
    },
    // 监听排行bar变化
    listenCompanyPatentBar() {
      EventBus.$on("company_patent_bar", (data) => {
        console.log("监听排行bar变化", data);
        this.patent_list = [];
        this.patentParams.page = 1;
        this.patentParams.company_guogao_id = data
        // this.patentParams.industry = ""
        this.patentParams.domain_id = "";
        this.getIndustryPatent();
        this.getIndustryPatentKeyword();
      });
    },
  },
  mounted() {
    this.post_params.account = cookie.getCookie("account")
    this.postInfoNodeParams.account = cookie.getCookie("account")
    // this.getIndustryPatent()
    this.env = process.env.VUE_APP_CURRENTMODE
    console.log("links", ev.links);
    console.log(
      "industry",
      JSON.parse(sessionStorage.getItem("index_industry"))
    );
    // this.getIndustryTree("新能源汽车")
    // this.newsParams.permissions = sessionStorage.getItem("permissions");
    this.getIndustryGraphOption();
    this.permissions = cookie.getCookie("permissions");
    cookie.setCookie("serverValue", "产业人才");
    this.handleClickIndustry(JSON.parse(sessionStorage.getItem("index_industry")))
    console.log("产业招聘：", JSON.parse(sessionStorage.getItem("index_industry")));
    let index_industry = JSON.parse(
      sessionStorage.getItem("index_industry")
    )
    this.patentParams.industry = index_industry.industry_en;
    this.industrySelected = index_industry.name;
    this.post_params.industry_node = index_industry.domain_id
    this.handleRadioChange("岗位分析");
    // this.listenReset();
    this.listenIndustryGraph();
    this.listenSourceIndustry();
    this.listenCompanyPatentBar();
    this.listenCompanyPostBar();
    this.listenCompanyPostRank();
    this.listenTreeClick();
    this.listenCompanyPostBarName()
    // this.getCategoryLink()
  },
  beforeDestroy() {
    // EventBus.$off("reset");
    EventBus.$off("industry_graph");
    EventBus.$off("choose_source_industry");
    EventBus.$off("company_post_bar");
    EventBus.$off("company_patent_bar");
    EventBus.$off("company_post_rank");
    EventBus.$off("tree_click");
    EventBus.$off("company_post_bar_name")
  },
};
</script>

<style lang="scss">
.industryTalent {
  // margin-top: 52px;
  width: 100%;
  padding-bottom: 10px;
  background-color: white;
  // margin-left: 0.5%;
  // min-height: 80vh;
  // padding-bottom: 30px;
  // max-height: 90vh;
  // display: flex;
  // justify-content: space-between;
  // background-color: white;
  .productAnalyse-patent {
    .el-icon-close::before {
      color: white;
    }
  }
  .el-form-item {
    margin-bottom: 2px;
  }
  &-left,
  &-right {
    // width: 49.6%;
    width: 100%;
    // max-height: 85vh;
    margin-top: 5px;
    background-color: white;
    border-radius: 6px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    padding-bottom: 5px;
  }
  &-right {
    margin-left: 5px;
  }
  .el-tabs__header {
    margin-bottom: 0;
    padding-left: 15px;
  }
  .contentFooter {
    // margin-bottom: 20px;
    height: 50px !important;
    margin-bottom: 2vh;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
  .newsEchartsBox {
    background-color: white;
    display: flex;
    min-height: 250px;
    width: 100%;
    // margin-top: 4px;
    border-radius: 6px;
  }
  .selectType {
    position: absolute;
    z-index: 10;
    top: 24vh;
    left: 6.5vw;
    .img {
      display: inline-block;
      // object-fit: fill;
      width: 50px;
      height: 40px;
      margin: 5px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      div {
        width: 50px;
        position: absolute;

        // background-color: rgba($color: #000000, $alpha: 0.5);
        text-align: center;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
      }
      .high {
        background-color: rgba($color: #000, $alpha: 0.5);
        color: #389bb7;
      }
      img {
        width: 50px;
        height: 40px;
        object-fit: cover;
      }
    }
  }
  tr:first-of-type {
    background-color: white;
  }
  #companyTable {
    margin-top: 20px;
  }
  .introduce {
    // position: absolute;
    // bottom: 9vh;
    width: 100%;
    // min-height: 0vh;
    border: 1px solid #e4eef6;
    // z-index: 1000;
    // transition-duration: 1s;
    // background-color: rgb(0, 0, 51);
    // box-shadow: inset 0px 0px 5px 3px rgb(5, 5, 129);
    .close {
      cursor: pointer;
      width: 23px;
      height: 23px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    table {
      width: 100%;
      color: #333333;
      margin-left: 0;
      // background-color: rgba($color: #000000, $alpha: 0.4);
      td {
        // border-bottom: solid rgba($color: white, $alpha: 0.3) 1px;
        padding: 10px;
        text-align: center;
      }
      td:nth-child(odd) {
        width: 13%;
        background-color: #f2f9fc;
      }
      td:nth-child(even) {
        width: 20%;
      }
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .des {
      overflow: hidden;
      flex: 1;
      padding: 10px;
      color: black;
      p {
        font-size: 14px;
        margin: 5px 0;
        span {
          display: inline-block;
          width: 48%;
          margin: 0 5px;
        }
      }
      h1 {
        margin: 5px 0;
        font-size: 18px;
        span {
          font-size: 14px;
          margin: 0 5px;
          //   background: #E7F4FF;
          color: cornflowerblue;
        }
      }
    }
    .detail {
      width: 100%;
      // max-width: 1200px;
      &-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: rgba(91, 91, 94, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          color: #d3feff;
          font-size: 30px;
          font-weight: bold;
        }
      }
      .detailTop {
        display: flex;
        // background-color: rgba($color: #000000, $alpha: 0.4);
        border-bottom: solid silver 1px;
        // img {
        //   margin: 20px 10px 20px 25px;
        //   object-fit: contain;
        //   height: 80px;
        //   width: 80px;
        //   background: white;
        // }
      }
      .compLogo {
        margin: 20px 10px 20px 25px;
        height: 80px;
        width: 80px;
        // background-color: white;
        // border: 1px solid gray;
        background-color: #01a4d9;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          width: 80px;
          overflow: hidden;
          word-break: break-all;
          word-wrap: wrap;
          margin-left: 7px;
          text-align: center;
          color: white;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 8px;
        }
      }
    }
  }
  .el-dialog__body {
    padding-top: 10px;
  }
  .radioBox {
    width: 100%;
    // position: absolute;
    // z-index: 1000;
    background-color: white;
    margin-top: 4px;
    padding-left: 15px;
    padding-top: 10px;
  }
  .nodeDesc {
    padding: 0 10px 0 10px;
    font-size: 15px;
  }
  table {
    margin-left: 0;
  }
  .market {
    padding: 0 15px 0 15px;
    margin-top: 20px;
    // display: flex;
    // justify-content: space-between;
    &-top {
      width: 98%;
      .industryDesc {
        margin-top: 10px;
        text-indent: 35px;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
    &-bottom {
      width: 98%;
      display: flex;
      justify-content: space-between;
    }
  }
  .tech {
    width: 100%;
    &-top {
      display: flex;
    }
  }
  .doubleArrow {
    width: 90%;
    margin-left: 5%;
  }
  .mores {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    &-text {
      cursor: pointer;
      background-color: #f4f4f4;
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: #d8d7d7;
      }
    }
  }
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow: auto !important;
  }
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgb(205, 205, 221);
    border-radius: 5px;
  }
  .tagBox {
    &-tag {
      margin-right: 7px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
  .skillRequirement {
    color: black;
    margin-top: 1vw;
    overflow-y: scroll;
    overflow-x: hidden;
    // .el-radio-button__inner {
      // background-color: transparent;
      // color: black;
    // }
    // element分页器样式改为暗黑风格 ↓
    // .el-pager li {
    //   background-color: rgba(7, 23, 27, 0.6) !important;
    // }
    // .btn-prev,
    // .btn-next {
    //   background-color: rgb(47, 146, 245) !important;
    // }
    // .el-pager li:not(.disabled).active {
    //   color: white !important;
    //   background-color: rgb(47, 146, 245) !important;
    // }
    // element分页器样式改为暗黑风格↑
    .el-table {
      color: black;
    }
    .el-table tbody tr:hover > td {
      background-color: transparent;
    }
    .el-table th,
    .el-table tr {
      background-color: transparent;
      color: #606266;
    }
    .el-table th {
      // background-color: silver;
      color: #9b9999;
    }
    .el-table,
    .el-table__expanded-cell {
      background-color: transparent;
    }
    .el-progress-bar__outer {
      background-color: rgba(0, 0, 0, 0.219);
    }
    .el-progress__text {
      color: black;
    }
    &-top,
    &-bottom {
      display: flex;
      width: 100vw;
      padding-left: 20px;
    }
    &-top {
      // margin-top: 0vh;
    }
    &-bottom {
      margin-top: 20px;
      width: 100%;
      display: block;
    }
    .tab {
      // position: absolute;
      margin-left: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;
      color: #0784ad;
      z-index: 1001;
      // margin-left: 4%;
      &-industry,
      &-company {
        padding: 0 10px 0 10px;
        height: 1.5vw;
        border: 1px solid #01a4d9;
        font-size: 1vw;
        // font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      &-industry {
        // border-top-left-radius: 4px;
        // border-bottom-left-radius: 4px;
        transform: skew(-20deg);
      }
      &-company {
        // border-top-right-radius: 4px;
        // border-bottom-right-radius: 4px;
        transform: skew(-20deg);
        border-right: none;
      }
      &-selected {
        background-color: #01a4d9;
        color: white;
      }
    }
    .el-input__inner {
      background-color: rgba(0, 0, 0, 0);
    }
    .toolBox {
      position: fixed;
      width: 100vw;
      height: 100vh;
      display: flex;
      top: 0;
      justify-content: center;
      padding-top: 20vh;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1000;
    }
    .tooltips {
      width: 40vw;
      height: 65vh;
      overflow-y: scroll;
      position: relative;
      // border-radius: 8px;
      // background-color: #323236;
      // opacity: 0.9;
      // border: 1px solid gray;
      background: linear-gradient(#23abf2, #23abf2) left top,
        linear-gradient(#23abf2, #23abf2) left top,
        linear-gradient(#23abf2, #23abf2) right top,
        linear-gradient(#23abf2, #23abf2) right top,
        linear-gradient(#23abf2, #23abf2) left bottom,
        linear-gradient(#23abf2, #23abf2) left bottom,
        linear-gradient(#23abf2, #23abf2) right bottom,
        linear-gradient(#23abf2, #23abf2) right bottom;
      background-repeat: no-repeat;
      background-color: rgba(5, 17, 29, 0.95);
      background-size: 1.5px 10px, 10px 1.5px;
      z-index: 101;
      color: white;
      padding: 10px;
    }
    .areaTitle {
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid white;
      display: flex;
      justify-content: space-between;
      &-close {
        cursor: pointer;
      }
    }
    .post {
      &-name {
        font-size: 18px;
        font-weight: bold;
      }
      &-company {
        font-size: 14px;
        margin-top: 5px;
      }
      &-info {
        margin-top: 25px;
        font-size: 14px;
      }
      &-line {
        margin-left: 5px;
        margin-right: 5px;
      }
      &-tag {
        border: 1px solid #01a4d9;
        border-radius: 4px;
        padding: 1px 3px 1px 3px;
        margin-right: 8px;
        background-color: none;
        display: inline-block;
        font-size: 14px;
        margin-top: 10px;
      }
      &-major {
        margin-top: 15px;
        font-size: 15px;
        // color: yellow;
      }
      &-majorContent {
        margin-top: 5px;
        font-weight: bold;
        letter-spacing: 2px;
        color: yellow;
      }
    }
    .skillBox {
      height: 45px;
      width: 10vw;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #409eff;
      // border: 2px solid #2f92f5;
      // color: #2f92f5;
      border-radius: 4px;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
}
</style>
