<template>
  <div class="postTable" id="postTable">
    <el-table
      :data="apiData"
      stripe
      style="width: 97%; margin: 10px 1.5% 20px 1.5%"
      border
      @row-click="showPostDetail"
    >
      <el-table-column prop="company_name" label="公司" width="180">
      </el-table-column>
      <el-table-column prop="name" label="岗位" width="160">
        <template slot-scope="scope">
          <p
            v-if="hasKeyword"
            v-html="highlight(scope.row.name_faiss, hasKeyword)"
          ></p>
          <p v-if="!hasKeyword" v-html="esHL(scope.row.name)"></p>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="地点"> </el-table-column>
      <el-table-column
        prop="num"
        label="招聘人数"
        sortable
        :sort-method="sortRule"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="salary" label="薪酬" sortable> </el-table-column>
      <el-table-column prop="release_date" label="时间" sortable>
      </el-table-column>
      <el-table-column
        v-if="(env != 'pro') & isEs"
        prop="score"
        label="评分"
        sortable
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        label="产品分类正确性"
        width="180"
        v-if="(env != 'pro') & (post_type == '按产品')"
      >
        <template slot-scope="scope">
          <div style="display: flex">
            <el-radio
              v-model="scope.row.industry_is_right"
              :label="1"
              @click.native.prevent.stop="changePostRight(scope.row, 1)"
              >正确</el-radio
            >
            <el-radio
              v-model="scope.row.industry_is_right"
              :label="0"
              @click.native.prevent.stop="changePostRight(scope.row, 0)"
              >错误</el-radio
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="标注" width="180" v-if="markable">
        <template slot-scope="scope">
          <el-select
            class="searchDetail-select"
            style="width: auto"
            size="mini"
            v-model="scope.row.mark"
            placeholder="请标注"
            @change="handleSelect(scope.row)"
          >
            <el-option
              v-for="item in markOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="收藏" width="90" align="center">
        <template slot-scope="scope">
          <Star
            :apiData="scope.row"
            :type="'post'"
            :isFavorites="isFavorites"
            @cancelStar="handleCancelStar"
          ></Star>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0 15px 0;
      "
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="page"
        :total="post_total"
        :page-size="page_size"
        :page-count="5"
        @current-change="handlePostPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="招聘信息" :visible.sync="showPost" append-to-body>
      <p
        class="postDetail-post"
        style="
          font-size: 20px;
          font-weight: bold;
          color: black;
          display: flex;
          justify-content: space-between;
          padding-right: 15px;
        "
      >
        {{ postInfoDetail.name }}
        <span style="font-size: 18px; color: #5c7bd9">{{
          postInfoDetail.salary
        }}</span>
      </p>
      <p class="postDetail-company" style="font-size: 16px; margin-top: 5px">
        {{ postInfoDetail.company_name }}
      </p>
      <p class="postDetail-info" style="margin-top: 25px">
        <span> {{ postInfoDetail.region }} </span>
        <span class="postDetail-line" style="margin: 0 5px 0 5px; color: silver"
          >|</span
        >
        <span> {{ postInfoDetail.experience }} </span>
        <span class="postDetail-line" style="margin: 0 5px 0 5px; color: silver"
          >|</span
        >
        <span> {{ postInfoDetail.education }} </span>
        <span class="postDetail-line" style="margin: 0 5px 0 5px; color: silver"
          >|</span
        >
        <span> 招{{ postInfoDetail.num ? postInfoDetail.num : "1" }}人 </span>
        <span class="postDetail-line" style="margin: 0 5px 0 5px; color: silver"
          >|</span
        >
        <span>{{ postInfoDetail.release_date }} 发布</span>
        <span class="postDetail-line" style="margin: 0 5px 0 5px; color: silver"
          >|</span
        >
        <span>{{ postInfoDetail.source }}</span>
      </p>
      <div v-if="postInfoDetail.key_word">
        <el-tag
          type="primary"
          size="small"
          style="margin-right: 8px; margin-top: 10px"
          v-for="t in postInfoDetail.keyword_tags"
          :key="t"
          >{{ t }}</el-tag
        >
      </div>
      <div v-if="postInfoDetail.other_label">
        <el-tag
          type="success"
          size="small"
          style="margin-right: 8px; margin-top: 10px"
          v-for="t in postInfoDetail.tags"
          :key="t"
          >{{ t }}</el-tag
        >
      </div>
      <p style="margin-top: 10px" v-if="postInfoDetail.post_category">
        职能类别：{{ postInfoDetail.post_category }}
      </p>
      <p
        class="postDetail-position_info"
        style="margin-top: 20px"
        v-html="postInfoDetail.position_info"
      ></p>
      <!-- <p class="postDetail-url"><a :href="postInfoDetail.url" style="color: #5C7BD9; font-weight: bold;font-size: 16px" target="_blank">前往原网页</a></p> -->
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api";
import utils from "@/utils/utils.js";
import cookie from "@/utils/common.js";
import Star from "@/components/Star.vue";

export default {
  name: "PostTable",
  props: {
    apiData: Array,
    env: String,
    post_type: String,
    page: Number,
    page_size: Number,
    post_total: Number,
    isFavorites: Boolean,
    isEs: Boolean,
    hasKeyword: String,
    markable: Boolean, // 是否可标注
    company_id: Number, // 该新闻列表对应的公司id（仅在产业搜索详情有用）
    searchValue: String, // 搜索内容（仅在产业搜索详情有用）
    searchType: Number
  },
  components: {
    Star,
  },
  watch: {
    apiData: {
      handler(newVal) {
        console.log("newVal", newVal);
        // newVal.forEach(n => {
        //   n.num == '若干' ? 999 : n.num
        // })
        utils.scrollToTopByClass("postTable"); // 滚动回顶部
      },
    },
  },
  data() {
    return {
      markOptions: [
        // 标记选项
        {
          label: "错误",
          value: 0,
        },
        {
          label: "尚可",
          value: 1,
        },
        {
          label: "良好",
          value: 2,
        },
        {
          label: "完美",
          value: 3,
        },
      ],
      showPost: false,
      postInfoDetail: {
        address: "",
        company_name: "",
        education: "",
        experience: "",
        job_type: "",
        label: "",
        name: "",
        num: "",
        other_label: "",
        position_info: "",
        region: "",
        release_date: "",
        salary: "",
        source: "",
        url: "",
        tags: [],
        keyword_tags: [],
      },
    };
  },
  // mounted () {
  //   let list = [1,7,6,2,5,1,NaN,8,NaN]

  //   list.sort(this.sortM)
  //   console.log("list", list)
  // },
  methods: {
    // sortM(a,b) {
    //   if (a == NaN) {
    //     return -1
    //   } else {
    //     return a - b
    //   }
    // },
    // 标注
    handleSelect(val) {
      console.log(val)
      let params = {
        keyword: this.searchValue,
        company_id: this.company_id,
        grade: val.mark,
        search_type: this.searchType,
        score: val.score,
        data_type: "post_name",
        data_id: val.id,
        value: val.name_faiss
      }
      console.log("params", params)
      api.changeCompanySearchMark(params).then(res => {
        console.log("res", res)
        if (res.data == 1) {
          this.$message.success("修改标注成功！")
        } else {
          this.$message.error("修改标注失败！")
        }
      }).catch(err => {
        this.$message.error("修改标注失败！")
        console.error("标注失败", err)
      })
    },
    sortRule(a, b) {
      let c = a.num == "若干" ? "999" : a.num;
      let d = b.num == "若干" ? "999" : b.num;
      return c - d;
    },
    // 修改es的highlight默认样式
    esHL(text) {
      if (!text) return; // 一开始text为空，会报错
      // console.log("text", text)
      text = text.replace(/<em>/g, '<font style="color:red;">');
      text = text.replace(/<\/em>/g, "</font>");
      // console.log("text", text)
      return text;
    },
    // 关键词红色高亮
    highlight(text, keyword, font_size) {
      if (text.indexOf(keyword) != -1) {
        text = text.replace(
          eval(`/${keyword}/g`),
          `<span style="color:red;font-size:${font_size};">${keyword}</span>`
        );
        // console.log("text", text)
        return text;
      } else {
        return text;
      }
    },
    // 响应取消收藏
    handleCancelStar(val) {
      this.$emit("cancelStar", 1);
      console.log("响应取消收藏", val);
      // utils.objArrRemove(this.apiNewsList, "id", val)
    },
    // 分页器变化
    handlePostPageChange(val) {
      this.$emit("changePostPage", val);
    },
    // 修改招聘产品判断
    changePostRight(i, val) {
      let params = {
        post_id: i.id,
        industry_is_right: val,
      };
      this.post_list.forEach((p) => {
        if (p.id == i.id) {
          p.industry_is_right = val;
        }
      });
      console.log("修改招聘产品判断val", i);
      this.$confirm("您确定要修改该招聘信息的产品判断吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .changePostRight(params)
            .then((res) => {
              if (res.status == 200) {
                this.$message.success("修改成功");
                // EventBus.$emit("change_polarity", 1)
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: "接口出错，请重试或联系管理员",
                type: "error",
              });
            });
        })
        .catch(() => {
          i.industry_is_right = null;
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    // 修改收藏
    handleSwitchChange(row) {
      console.log(row);
      let params = {
        user: cookie.getCookie("account"),
        data_id: row.id,
        star_type: "post",
      };
      if (row.star == true) {
        api.addUserStar(params).then((res) => {
          console.log("res", res);
          if (res.data == 1) {
            this.$message.success("添加收藏成功！");
          } else {
            this.$message.error("添加收藏失败！");
          }
        });
      } else {
        api.cancelUserStar(params).then((res) => {
          console.log("res", res);
          if (res.status == 200) {
            if (this.isFavorites) {
              // utils.objArrRemove(this.apiData, "id", row.id)
              this.$emit("cancelStar", 1);
            }
            this.$message.success("取消收藏成功！");
          } else {
            this.$message.error("取消收藏失败！");
          }
        });
      }
    },
    // 点击招聘信息
    showPostDetail(val) {
      console.log("点击招聘信息", val);
      this.getPostDetail(val.id);
      this.postInfoDetail = {};
      this.postInfoDetail.tags = [];
      this.postInfoDetail.keyword_tags = [];
      // this.showPost = true;
    },
    // 获取招聘信息详情
    getPostDetail(id) {
      let params = {
        id: id,
      };
      api.getPostDetail(params).then((res) => {
        console.log("获取招聘信息详情", res);
        this.postInfoDetail = res.data;
        this.postInfoDetail.tags = res.data.other_label.split("|");
        this.postInfoDetail.keyword_tags = res.data.key_word.split("|");
        this.$nextTick(() => {
          this.showPost = true;
          console.log("showPost", this.showPost);
        });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.postTable {
  height: 100%;
  overflow-y: scroll;
  .postDetail {
    &-post {
      font-size: 20px;
      font-weight: bold;
      color: black;
      display: flex;
      justify-content: space-between;
      padding-right: 15px;
      span {
        font-size: 18px;
        color: #5c7bd9;
      }
    }
    &-company {
      font-size: 16px;
      margin-top: 5px;
    }
    &-info {
      margin-top: 25px;
    }
    &-line {
      margin: 0 5px 0 5px;
      color: silver;
    }
    &-position_info {
      margin-top: 20px;
    }
    &-url {
      margin-top: 10px;
    }
  }
}
</style>
