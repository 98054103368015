<template>
  <div class="industryPatentEcharts">
    <div class="lineChart" ref="lineChartPatent"></div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js"

export default {
  name: "PatentEcharts",
  props: {
    apiDataLineChart: Array,
  },
  data() {
    return {
      lineChartPatent: "",
      yearData: []
    };
  },
  watch: {
    apiDataLineChart: {
      deep: true,
      handler(newVal, oldVal) {
        console.log(newVal)
        this.setOriginLineChartPatent()
        this.setLineChartPatent()
      }
    }
  },
  mounted() {
    let this_year = new Date().getFullYear()
    console.log("this_year", this_year)
    for(let i=this_year-11;i<=this_year;i++) {
      this.yearData.push(i)
    }
    console.log("yearData", this.yearData)
  },
  methods: {
    sendYear(year) {
      EventBus.$emit('year', year)
    },
    // 专利折线图echarts初始化
    setOriginLineChartPatent() {
      echarts.init(this.$refs.lineChartPatent).dispose();
      this.lineChartPatent = echarts.init(this.$refs.lineChartPatent);
      let that = this;
      this.lineChartPatent.on("click", function (params) {
        console.log("click", params.name);
        that.sendYear(params.name)
        // that.year = params.name;
        // let params_patent = {
        //   company_id: sessionStorage.getItem("company_id"),
        //   page_size: 20,
        //   year: that.year,
        // };
        // api.getPatent(params_patent).then((res) => {
        //   console.log("patent", res);
        //   that.patentsList = res.data;
        // });
        // that.wordData =
        //   that.company_info_json[that.$route.query.company_name].data.keywords[
        //     that.year
        //   ];
        // that.wordData = that.wordCloudKeywords[that.year];
        // console.log("keywords", that.wordData);
        // that.setWordCloud();
      });
    },
    // 专利折线图渲染
    setLineChartPatent() {
      let option = {
        title: {
          text: "产业专利数量趋势图",
          textStyle: {
            color: "#333333",
            fontWeight: "normal",
            fontSize: "16",
          },
        },
        itemStyle: {
          color: "#01a4d9",
          opacity: 0.8,
        },
        grid: {
          left: "8%",
          right: "5%",
          top: "15%",
          bottom: "5%",
          containLabel: true,
        },
        label: {
          show: true,
          color: "#333333"
        },
        textStyle: {
          color: "white",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{c}",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          axisLabel: {
            color: "#6c706f",
            interval: 0,
            rotate: 40,
          },
          type: "category",
          boundaryGap: false,
          data: this.yearData,
          // data: ["2016","2017", "2018", "2019", "2020"],
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            data: this.apiDataLineChart,
            type: "line",
          },
        ],
      };
      this.lineChartPatent.setOption(option);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .industryPatentEcharts {
    width: 55%;
    .lineChart {
      width: 100%;
      margin-left: 10px;
      margin-right: 5%;
      margin-top: 1vw;
      height: 22vh;
      border: 1px solid rgb(191, 192, 197);
      border-radius: 4px;
    }
  }
</style>
