<template>
  <div class="map">
    <div ref="map" class="charts" :style="{ height: eheight }"></div>
  </div>
</template>

<script>
import json from "@/json/china.json";
// import guangdong from '@/json/province/guangdong.json'
// import shenzhen from '@/json/city/shenzhen.json'

export default {
  name: "china",
  props: ["eheight", "data"],
  data() {
    return {
      map: "",
      geoCoordMap: {
        黑龙江: [127.9688, 45.368],
        内蒙古: [110.3467, 41.4899],
        吉林: [125.8154, 44.2584],
        北京: [116.4551, 40.2539],
        辽宁: [123.1238, 42.1216],
        河北: [114.4995, 38.1006],
        天津: [117.4219, 39.4189],
        山西: [112.3352, 37.9413],
        陕西: [109.1162, 34.2004],
        甘肃: [103.5901, 36.3043],
        宁夏: [106.3586, 38.1775],
        青海: [101.4038, 36.8207],
        新疆: [87.9236, 43.5883],
        西藏: [91.11, 29.97],
        四川: [103.9526, 30.7617],
        重庆: [108.384366, 30.439702],
        山东: [117.1582, 36.8701],
        河南: [113.4668, 34.6234],
        江苏: [118.8062, 31.9208],
        安徽: [117.29, 32.0581],
        湖北: [114.3896, 30.6628],
        浙江: [119.5313, 29.8773],
        福建: [119.4543, 25.9222],
        江西: [116.0046, 28.6633],
        湖南: [113.0823, 28.2568],
        贵州: [106.6992, 26.7682],
        云南: [102.9199, 25.4663],
        广东: [113.12244, 23.009505],
        广西: [108.479, 23.1152],
        海南: [110.3893, 19.8516],
        上海: [121.4648, 31.2891],
        台湾: [121.5, 25.03],
        香港: [114.08, 22.2],
        澳门: [113.33, 22.13],
      },
      // name: {
      //   广东: "广东",
      //   河南: "河南",
      //   北京: "北京",
      //   上海: "上海"
      // }
    };
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        this.setPoint();
        // console.log("MapData", this.data);
      },
      deep: true,
    },
  },
  mounted() {
    // console.log("MapData", this.data);
    this.mapChart();
    this.setPoint();
    window.onresize = () => {
      // 根据窗口大小自适应
      this.map.resize();
    };
  },
  methods: {
    mapChart() {
      /*获取地图数据*/
      // var max = 480,
      //   min = 9;
      // var maxSize4Pin = 100,
      //   minSize4Pin = 20;

      let that = this;
      echarts.registerMap("map", {});
      this.map = echarts.init(this.$refs["map"], null, { devicePixelRatio: 4 });

      // var mapFeatures = echarts.getMap("map").geoJson.features;

      // mapFeatures.forEach(function(v) {
      //   // 地区名称
      //   var name = v.properties.name;
      //   // 地区经纬度
      //   that.geoCoordMap[name] = v.properties.cp;
      // });

      this.map.setOption({
        tooltip: {
          textStyle: {
            color: "#ffffff",
          },
          // data: convertData,
          backgroundColor: "rgba(166, 200, 76, 0.82)",
        }, // 鼠标移到图里面的浮动提示框

        geo: {
          // 这个是重点配置区
          map: "china", // 表示中国地图
          // nameMap: that.name,
          roam: false,
          zoom: 1.2,
          label: {
            show: false,

            normal: {
              show: false,
              textStyle: {
                color: "#ffffff",
              },
            },
            emphasis: {
              show: false,
              textStyle: {
                color: "white",
                backgroundColor: "#555555",
                padding: 2,
                fontSize: 14,
                lineHeight: 16,
                borderRadius: 2,
              },
            },
          },

          itemStyle: {
            normal: {
              areaColor: "#389BB7",
              borderColor: "#0a53e9", //线
              shadowColor: "raba(128,217,248,1)", //外发光
              shadowBlur: 20,
            },
            emphasis: {
              areaColor: "#00eaff",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: "#2B91B7",
            },
          },
        },
        series: [],
      });
    },
    async setPoint() {
      let that = this;
      // let t = this.filterData();
      await that.data;
      // console.log("setPoint");
      // console.log("data", that.data);
      var convertData = function(data) {
        let res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = that.geoCoordMap[data[i].loc_province];
          if (geoCoord) {
            res.push({
              name: data[i].loc_province,
              value: geoCoord.concat(data[i].value),
            });
          }
        }
        return res;
      };
      this.map.setOption({
        tooltip: {
          formatter: function(value) {
            return value.name + "：共" + value.value[2] + "个相关岗位";
          },
          // 解决放大后tooltip被地图遮挡 以及 超出div
          // position: function(point, params, dom, rect, size) {
          //   dom.style.transform = "translateZ(0)";
          //   return [0, point[1]];
          // }
        },
        series: {
          name: "散点",
          type: "effectScatter",
          coordinateSystem: "geo",
          // data: convertData(that.data),
          data: convertData(that.data),
          rippleEffect: {
            //涟漪特效
            period: 4, //动画时间，值越小速度越快
            brushType: "stroke", //波纹绘制方式 stroke, fill
            scale: 4, //波纹圆环最大限制，值越大波纹越大
          },
          symbol: "circle",
          symbolSize: function(val) {
            return 8; //圆环大小
          },

          label: {
            normal: {
              show: false,
              position: "right",
              // formatter: function(value) {
              //   return value.loc_province;
              // },
              textStyle: {
                show: false,
                color: "#fff",
                fontSize: 14,
              },
            },
          },
          itemStyle: {
            normal: {
              color: "#FFFF33", //标志颜色
            },
          },
          zlevel: 7,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.charts {
  width: 100%;
  height: 100%;
}
</style>
