<template>
  <div class="newsTypeBar">
    <div class="barEmpty" v-if="apiDataBarChart.legend.length==0">
      <img src="@/assets/images/empty_blue.png">
      <p>暂无结果</p>
    </div>
    <div class="bar" ref="bar"></div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";

export default {
  name: "NewsTypePie",
  props: {
    apiDataBarChart: Object,
  },
  data() {
    return {
      myBar: "",
      barLegend: [],
      barData: [],
    };
  },
  watch: {
    apiDataBarChart: {
      handler(newVal, oldVal) {
        console.log("newVal", newVal);
        // newVal.forEach(i => {
        //   this.barLegend.push(i.name)
        //   this.barData.push(i.value)
        // });
        // console.log(this.barLegend)
        this.setOriginBar();
        this.setBar();
      },
      deep: true,
    },
  },
  // mounted() {
  //   this.setOriginBar();
  //   this.setBar();
  // },
  methods: {
    // 柱状图初始化
    setOriginBar() {
      echarts.init(this.$refs.bar).dispose();
      this.myBar = echarts.init(this.$refs.bar);
      // this.myBar.on("click", function (params) {
      //   console.log(params.name);
      //   EventBus.$emit("news_filter", params.name);
      // });
      this.myBar.getZr().on("click", (params) => {
        // console.log("barParams", params)
        const pointInPixel = [params.offsetX, params.offsetY];
        if (this.myBar.containPixel("grid", pointInPixel)) {
          let xIndex = this.myBar.convertFromPixel({ seriesIndex: 0 }, [
            params.offsetX,
            params.offsetY,
          ])[0];
          /*事件处理代码书写位置*/
          console.log("zr", this.apiDataBarChart.data[xIndex]);
          EventBus.$emit("company_patent_bar", this.apiDataBarChart.data[xIndex].company_guogao_id)
          // EventBus.$emit("news_filter", this.apiDataBarChart.legend[xIndex]);
        }
      });
      // this.myBar.getZr().on("click", function (params) {
      //   const pointInPixel = [params.offsetX, params.offsetY];
      //   if (this.myBar.containPixel("grid", pointInPixel)) {
      //     let xIndex = this.echart.convertFromPixel({ seriesIndex: 0 }, [
      //       params.offsetX,
      //       params.offsetY,
      //     ])[0];
      //     /*事件处理代码书写位置*/
      //     console.log("xindex", xIndex)
      //   }
      // });
    },
    // 设置柱状图
    setBar() {
      let that = this
      this.myBar.setOption({
        color: ["#5C7BD9"],
        xAxis: {
          type: "category",
          data: this.apiDataBarChart.legend,
          axisLabel: {
            color: "#6c706f",
            interval: 0,
            rotate: this.apiDataBarChart.legend.length > 8? 30: 0,
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{c}",
          formatter: function(data) {
            // console.log("Data", data)
            return that.apiDataBarChart.name[data[0].axisValue]
          },
          axisLabel: {
            color: "#6c706f",
            interval: 0,
            rotate: this.apiDataBarChart.legend.length > 8? 30: 0,
          },
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          // triggerOn: "click",
        },
        title: {
          text: "行业公司专利排行",
          left: "center",
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            // data: [120, 200, 150, 80, 70, 110, 130],
            data: this.apiDataBarChart.data,
            type: "bar",
            label: {
              show: true,
              position: "top",
            },
            barWidth: this.apiDataBarChart.legend.length > 8? "30": "40",
          },
        ],
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.newsTypeBar {
  width: 49.5%;
  margin-left: 0px;
  margin-top: 5px;
  background-color: white;
  padding-left: 10px;
  border-radius: 6px;
  padding-top: 10px;
  -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  .bar {
    min-height: 250px;
    // width: 50%
  }
  .barEmpty {
    width: 100%;
    height: 250px;
    position: absolute;
    flex-direction: column;
    // background-color: rgba(0, 0, 0, 0.7);
    // color: white;
    color: gray;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      margin-bottom: 10px;
    }
  }
}
</style>
